import React, { useState } from 'react';
import type { ReactNode } from 'react';

import { Patient } from '@/src/externalApi/models/Patient';

import ProfileContext from './ProfileContext';

const useProfileContextProvider = () => {
  const [profile, setProfile] = useState<Patient | undefined>();

  return {
    profile,
    setProfile,
  };
};

const ProfileContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useProfileContextProvider();
  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export type ProfileContextValue = ReturnType<typeof useProfileContextProvider>;

export default ProfileContextProvider;
